import * as React from "react";
import Layout from "../../../components/Layout";
import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import ScopriAltriBonusECM from "../../../components/ScopriAltriBonusECM/ScopriAltriBonusECM";

export default function FormazioneIndividualePage() {
  return (
    <Layout pageTitle="Bonus ECM - Formazione Individuale"
    description="Bonus ECM: sconto sui crediti da acquisire tramite la formazione individuale">
      <section className="position-relative">
        <StaticImage
          src="../../../images/background-bonus-crediti.png"
          className="img-header-home"
          alt="Bonus ECM"
          title="Bonus ECM"
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1
              title="Bonus ECM - Il ricorso alla formazione individuale permette di
              acquisire crediti ECM"
              className="text-center text-white display-3"
            >
              Bonus ECM - Il ricorso alla formazione individuale permette di
              acquisire crediti ECM
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Con la formazione individuale hai diritto ad ottenere crediti ECM
              per assolvere l'obbligo formativo.
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri di più
            </a>
          </div>
        </div>
      </section>
      <div id="sezione-corsi" className="container-fluid p-5">
        <p>
          La Commissione Nazionale per la Formazione Continua ha approvato, in
          data 08/06/2022, la lista di attività facenti parte della formazione
          individuale che prevedono, al pari della classica attività di
          partecipazione ai corsi erogati dai Provider, l’acquisizione di
          crediti formativi; nello specifico:
        </p>
        <p>
          <b>Attività di ricerca scientifica</b>
        </p>
        <ul>
          <li>
            Pubblicazioni scientifiche: I professionisti sanitari autori di
            pubblicazioni scientifiche censite nelle banche dati internazionali
            Scopus e Web of Science l Web of Knowledge maturano il diritto al
            riconoscimento, per singola pubblicazione, di:
            <br />
            - 3 crediti (se in posizione preminente: primo, secondo nome e/o
            ultimo nome o corresponding).
            <br />- 1 credito (se in posizione non preminente).
          </li>
        </ul>
        <p>
          <b>Tutoraggio individuale</b>
        </p>
        <ul>
          <li>
            {" "}
            I professionisti sanitari che svolgono attività di tutoraggio
            individuale in ambito universitario e nei corsi di formazione
            specifica in medicina generale seminariali o pratiche, maturano il
            diritto al riconoscimento di 1 credito formativo ogni 15 ore di
            attività. Sono compresi in tale riconoscimento, altresì, le seguenti
            figure:
          </li>
          <li>
            i Coordinatori/direttori delle attività professionalizzanti dei
            corsi delle professioni sanitarie;
          </li>
          <li>il direttore del coordinamento;</li>
          <li>
            il direttore delle attività didattiche integrate, seminariali o
            pratiche nei corsi di formazione specifica in medicina generale.
          </li>
        </ul>
        <p>
          <b>Attività di formazione individuale all’estero</b>
          <ul>
            <li>
              {" "}
              Il professionista sanitario può maturare crediti formativi come
              attività di formazione individuale all’estero in virtù di
              convenzioni, anche transfrontaliere, per il mutuo riconoscimento
              delle attività formative stipulate, oltre che dalla CNFC, anche
              dalle Regioni, dalle Province autonome di Trento e Bolzano, dagli
              Ordini, e loro Federazioni, nonché da altri enti pubblici non
              economici aventi finalità di formazione. Ai professionisti
              sanitari che frequentano corsi di formazione individuale
              all’estero sono riconosciuti crediti ECM nella misura del 50% dei
              crediti attribuiti dal singolo evento accreditato all’estero. I
              crediti acquisibili tramite formazione individuale all’estero non
              possono superare il 50% dell’obbligo formativo triennale. Nel caso
              in cui l’evento accreditato all’estero supera i 50 crediti
              formativi sono riconosciuti al massimo 25 crediti ECM. Nel caso in
              cui esistano accordi tra regioni transfrontaliere validamente
              sottoscritti e comunicati alla Commissione nazionale e/o ente
              accreditante regionale possono essere riconosciuti tutti i crediti
              acquisiti tramite l’evento formativo accreditato all’estero e
              comunque non oltre i 50 crediti per evento. È definita formazione
              individuale all’estero la formazione non accreditata in Italia e
              svolta nei paesi dell’U.E., in Svizzera, negli Stati Uniti e in
              Canada’.
            </li>
          </ul>
          Visita il{" "}
          <a
            href="http://wp.cogeaps.it/wp-content/uploads/2019/02/Guida-Crediti-ECM-crediti-estero.pdf"
            title="Crediti ECM per Formazione individuale all'estero"
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>{" "}
          per la Guida ai crediti ECM ottenuti all'estero{" "}
        </p>
        <p></p>
        <p>
          <b>Attività di autoformazione</b>
        </p>
        <p>L'attività di autoformazione consiste nella lettura di:</p>
        <ul>
          <li>riviste scientifiche</li>
          <li>capitoli di libri e di monografie</li>
          <li>manuali tecnici per attrezzature di alto livello tecnologico</li>
          <li>
            ogni altra fonte necessaria alla preparazione per l'iscrizione negli
            elenchi ed albi ministeriali non accreditati come eventi formativi
            ECM.
          </li>
        </ul>
        <p>
          Per il triennio formativo in corso il numero complessivo di crediti
          riconoscibili per attività di autoformazione{" "}
          <b>non può superare il 20% dell'obbligo formativo triennale</b>,
          includendo l'attività di studio finalizzata alla docenza in master
          universitari, corsi di alta formazione e di perfezionamento e le
          scuole di specializzazione valutando, sulla base dell'impegno orario
          autocertificato dal professionista, il numero dei crediti da
          attribuire. Rimane ferma la facoltà di Federazioni e Ordini di
          prevedere ulteriori tipologie di autoformazione sulla base delle
          esigenze delle specifiche professioni.
        </p>
        <p>
          Svolgendo una o più delle seguenti attività si avrà diritto
          all’acquisizione di crediti formativi ecm che permetteranno ai
          professionisti sanitare di maturare crediti senza seguire le attività
          erogate dai Provider.{" "}
        </p>
        <p>
        Per il triennio 2020/2022 i crediti maturabili tramite le suddette attività di formazione individuale
        non possono complessivamente superare il <b>60% dell’obbligo formativo triennale</b>
        tenendo conto anche dei crediti acquisibili con le docenze, fermo restando il limite del 20% per
        l’autoformazione. Per richiedere tali
          riconoscimenti è necessario{" "}
          <b>
            fare richiesta tramite la propria area riservata del portale
            Co.Ge.A.P.S.
          </b>
        </p>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h4
              title="Vuoi rimanere sempre aggiornato sulle novità Bonus ECM?"
              className="my-4 text-center titolo-sezione fw-bold"
              style={{ fontSize: 32 }}
            >
              Vuoi rimanere sempre aggiornato sulle novità Bonus ECM?
            </h4>
            
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-12 text-center">
            <a
              href="http://d7g5i.emailsp.com/frontend/forms/Subscription.aspx?idList=28&idForm=285&guid=5ddc68f0-845c-4ca3-b4ef-d19c93033a5c"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-primary btn-lg"
            >
              Iscriviti alla newsletter
            </a>

           <p className="text-center">I tuoi dati non verranno condivisi con nessun Provider.</p>

          </div>
        </div>
      </div>
      <ScopriAltriBonusECM
        dossierFormativo={true}
        emergenzaSanitaria={true}
        sperimentazioneMedicinale={true}
      />
    </Layout>
  );
}
